import React, { Fragment } from "react"
import Button from "antd/lib/button"
import MeasageIcon from "../icons/mrfood-icon/measage"
import TeamIcon from "../icons/mrfood-icon/team"
import SuitcaseIcon from "../icons/mrfood-icon/suitcase"
import ScheduleIcon from "../icons/mrfood-icon/schedule"
import JpCurrencyIcon from "../icons/mrfood-icon/jp-currency"
import { navigate } from "gatsby"
import SiteIcon from "../icons/mrfood-icon/site"

const MenuButton = () => {
  const btns = [
    {
      icon: <MeasageIcon />,
      text: "メッセージ管理",
      url: "/super-chat/?pageCurrent=1&pageSize=10",
    },
    {
      icon: <SuitcaseIcon />,
      text: "掲載管理",
      url: "/super-list-company/?pageCurrent=1&pageSize=10",
    },
    {
      icon: <ScheduleIcon />,
      text: "販売管理",
      url: "/order-management/?pageCurrent=1&pageSize=10",
    },
    // {
    //   icon: <SiteIcon />,
    //   text: "販売管理",
    //   url: "/super-ad-site-management",
    // },
    {
      icon: <JpCurrencyIcon />,
      text: "売上管理",
      url: "/super-overview",
    },
  ]
  return btns.map((btn, index) => {
    return (
      <Fragment>
        <Button
          key={index}
          className="flex justify-center items-center min-w-[180px] min-h-[180px] !bg-orange-2 border-[1px]  !rounded-lg border-solid border-orange-2 mx-3.5 box-border"
          onClick={() => {
            navigate(btn.url)
          }}
        >
          <div className="flex justify-center" style={{ fontSize: 14 }}>
            {btn.icon}
          </div>
          <div className="tracking-widest mt-3 font-sans font-medium text-[14px] text-grey-6 leading-6 ">
            {btn.text}
          </div>
        </Button>
      </Fragment>
    )
  })
}

export default MenuButton
