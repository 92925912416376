import React from "react";

const SuitcaseIcon = () => {
    return (
        <svg width="28" height="33" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.7273 8.02628H21.3182V7.38628C21.3182 3.32228 18.0409 0.0262756 14 0.0262756C9.95909 0.0262756 6.68182 3.32228 6.68182 7.38628V8.02628H1.27273C0.56875 8.02628 0 8.59828 0 9.30628V30.7463C0 31.4543 0.56875 32.0263 1.27273 32.0263H26.7273C27.4312 32.0263 28 31.4543 28 30.7463V9.30628C28 8.59828 27.4312 8.02628 26.7273 8.02628ZM9.54545 7.38628C9.54545 4.91028 11.5381 2.90628 14 2.90628C16.4619 2.90628 18.4545 4.91028 18.4545 7.38628V8.02628H9.54545V7.38628ZM25.1364 29.1463H2.86364V10.9063H6.68182V14.4263C6.68182 14.6023 6.825 14.7463 7 14.7463H9.22727C9.40227 14.7463 9.54545 14.6023 9.54545 14.4263V10.9063H18.4545V14.4263C18.4545 14.6023 18.5977 14.7463 18.7727 14.7463H21C21.175 14.7463 21.3182 14.6023 21.3182 14.4263V10.9063H25.1364V29.1463Z" fill="#FAFAFA" />
        </svg>

    )
}

export default SuitcaseIcon