import React from "react"

const ScheduleIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.15 17.027C19.245 17.027 17.7 18.572 17.7 20.477C17.7 22.382 19.245 23.927 21.15 23.927C23.055 23.927 24.6 22.382 24.6 20.477C24.6 18.572 23.055 17.027 21.15 17.027ZM21.15 21.752C20.445 21.752 19.875 21.182 19.875 20.477C19.875 19.772 20.445 19.202 21.15 19.202C21.855 19.202 22.425 19.772 22.425 20.477C22.425 21.182 21.855 21.752 21.15 21.752ZM28.8 2.13946H20.85C20.85 0.980709 19.9088 0.0394592 18.75 0.0394592H15.75C14.5912 0.0394592 13.65 0.980709 13.65 2.13946H5.7C5.03625 2.13946 4.5 2.67571 4.5 3.33946V10.8395H1.2C0.53625 10.8395 0 11.3757 0 12.0395V28.8395C0 29.5032 0.53625 30.0395 1.2 30.0395H13.8C14.4637 30.0395 15 29.5032 15 28.8395V28.2395H28.8C29.4637 28.2395 30 27.7032 30 27.0395V3.33946C30 2.67571 29.4637 2.13946 28.8 2.13946ZM13.35 4.53946H16.05V2.43946H18.45V4.53946H21.15V6.33946H13.35V4.53946ZM12.6 27.6395H2.4V18.9395H12.6V27.6395ZM12.6 16.5395H2.4V13.2395H12.6V16.5395ZM27.3 25.5395H15V12.0395C15 11.3757 14.4637 10.8395 13.8 10.8395H7.2V4.83946H10.95V8.73946H23.55V4.83946H27.3V25.5395ZM22.2 11.1395V14.7395C22.2 14.9045 22.335 15.0395 22.5 15.0395H24.3C24.465 15.0395 24.6 14.9045 24.6 14.7395V11.1395C24.6 10.9745 24.465 10.8395 24.3 10.8395H22.5C22.335 10.8395 22.2 10.9745 22.2 11.1395ZM18 15.0395H19.8C19.965 15.0395 20.1 14.9045 20.1 14.7395V12.6395C20.1 12.4745 19.965 12.3395 19.8 12.3395H18C17.835 12.3395 17.7 12.4745 17.7 12.6395V14.7395C17.7 14.9045 17.835 15.0395 18 15.0395Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}

export default ScheduleIcon
