import { CaretRightOutlined } from "@ant-design/icons"
import { Col, Divider, Row, Skeleton, Space, Spin, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { getListNews } from "../../services/news"
import moment from "moment"
import "./style.scss"
const { Text } = Typography

function NotifyTop() {
  const [loading, setLoading] = useState(false)
  const [listNews, setListNews] = useState(null)

  const fetchListNews = async () => {
    setLoading(true)
    const { response } = await getListNews({
      limit: 5,
      offer: 0,
      orderDESC: ["schedule"],
    })
    if (response.status == 200) {
      setLoading(false)
      console.log("responseresponse", response)

      setListNews(response.data.news)
    }
  }
  useEffect(() => {
    fetchListNews()
  }, [])
  console.log("listNews", listNews)
  return (
    <div style={{ marginTop: "3rem" }}>
      <div style={{ width: "100%", textAlign: "center" }}>
        {" "}
        <Spin tip="Loading..." spinning={loading}>
          {loading && <Skeleton />}
        </Spin>
      </div>
      {listNews && (
        <div>
          <Space direction="vertical" size={0} style={{ width: "100%" }}>
            {listNews.map((item, index) => (
              <div
                key={index}
                className={`news_content ${!item.isReaded ? "not_read" : ""}`}
                style={{ paddingTop: 10 }}
              >
                <Row
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/news/detail/${item.id}`)
                  }}
                >
                  <Col xs={7} md={5} className={"notifyDate"}>
                    {moment(item.schedule).format("YYYY/MM/DD")}
                  </Col>
                  <Col xs={17} md={19} className={"notifyText"}>
                    {item.title}
                  </Col>
                </Row>
                <Divider style={{ margin: 0, marginTop: 10 }} />
                <div
                  style={{
                    borderRadius: "3px",
                    background:
                      (item.news_type == "news" && "#F0F5FF") ||
                      (item.news_type == "notification" && "#FCEFDB") ||
                      "unset",
                    textAlign: "center",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    padding: "0 10px",
                    color: "#333",
                  }}
                >
                  {item.news_type == "news" && "ニュース"}
                  {item.news_type == "notification" && "お知らせ"}
                </div>
              </div>
            ))}
          </Space>
          <div className={"notifyLink pb-6"}>
            <Text
              underline
              type="danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/super-ad-news/?pageCurrent=1&pageSize=10")
              }}
            >
              お知らせ一覧を見る
              <CaretRightOutlined />
            </Text>
          </div>
        </div>
      )}
    </div>
  )
}

export default NotifyTop
